import { useCallback } from 'react';

import { unregisterTrackedUser } from 'kognia/error-tracking/register-user';
import { useResetFeatureFlags } from 'shared/contexts/app-state/hooks/useResetFeatureFlags';
import { useResetUserState } from 'shared/contexts/app-state/hooks/useResetUserState';
import { useSetAppState } from 'shared/contexts/app-state/hooks/useSetAppState';
import { useSetIsAuthorized } from 'shared/contexts/app-state/hooks/useSetIsAuthorized';
import { PAGE_STATES } from 'shared/hooks/use-page-state-machine';

export function useUnauthorize() {
  const setAppState = useSetAppState();
  const resetUserState = useResetUserState();
  const setIsAuthorized = useSetIsAuthorized();
  const resetFeatureFlagsState = useResetFeatureFlags();

  return useCallback(() => {
    unregisterTrackedUser();
    setIsAuthorized(false);
    resetFeatureFlagsState();
    resetUserState();
    setAppState(PAGE_STATES.ERROR);
  }, [resetFeatureFlagsState, resetUserState, setAppState, setIsAuthorized]);
}
