import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { TIMELINE_DEFAULT_FILTERS } from 'api/recording/useEpisodesWithFilters/useTacticalAnalysesPlaylistItems';
import { timelineMatchAtoms } from 'pages/tactical-analysis/atoms';
import { useSetTacticalAnalysisAvailableFilters } from 'pages/tactical-analysis/hooks/use-tactical-analysis-available-filters';
import { useSetTacticalAnalysisEpisodes } from 'pages/tactical-analysis/hooks/use-tactical-analysis-episodes';
import { useSetTacticalAnalysisId } from 'pages/tactical-analysis/hooks/use-tactical-analysis-id';
import { useSetTacticalAnalysisMatch } from 'pages/tactical-analysis/hooks/use-tactical-analysis-match';
import { useSetTacticalAnalysisMatchSegments } from 'pages/tactical-analysis/hooks/use-tactical-analysis-match-segments';
import { useSetTacticalAnalysisTeams } from 'pages/tactical-analysis/hooks/use-tactical-analysis-teams';
import { MatchWithEpisodes } from 'shared/types/match/types';

export const useUpdateTacticalAnalysisAtoms = (recordingId: string) => {
  const setTacticalAnalysisTeams = useSetTacticalAnalysisTeams(recordingId);
  const setCurrentTacticalAnalysisId = useSetTacticalAnalysisId(recordingId);
  const setTacticalAnalysisAvailableFilters = useSetTacticalAnalysisAvailableFilters(recordingId);
  const setTacticalAnalysisMatch = useSetTacticalAnalysisMatch(recordingId);
  const setTacticalAnalysisEpisodes = useSetTacticalAnalysisEpisodes(recordingId);
  const setMatchSegments = useSetTacticalAnalysisMatchSegments(recordingId);
  const setTimelineTeamIdFocus = useSetRecoilState(timelineMatchAtoms.teamIdFocus(recordingId));
  const setTimelineVideoSource = useSetRecoilState(timelineMatchAtoms.matchVideoSource(recordingId));

  return useCallback(
    (matchWithEpisodes: MatchWithEpisodes) => {
      setTacticalAnalysisMatch(matchWithEpisodes.match);
      setTacticalAnalysisAvailableFilters(matchWithEpisodes.filters ?? TIMELINE_DEFAULT_FILTERS);
      setTacticalAnalysisEpisodes(matchWithEpisodes.episodes);
      setCurrentTacticalAnalysisId(matchWithEpisodes?.episodes[0]?.tacticalAnalysisId ?? '');
      setTacticalAnalysisTeams(matchWithEpisodes.match.teams);
      setTimelineVideoSource(matchWithEpisodes.match.defaultVideoSource);
      setMatchSegments(matchWithEpisodes.matchSegments);
      setTimelineTeamIdFocus((focusTeamId) => (focusTeamId ? focusTeamId : matchWithEpisodes.match.teams.homeTeam?.id));
    },
    [
      setTacticalAnalysisMatch,
      setTacticalAnalysisAvailableFilters,
      setTacticalAnalysisEpisodes,
      setCurrentTacticalAnalysisId,
      setTacticalAnalysisTeams,
      setTimelineVideoSource,
      setMatchSegments,
      setTimelineTeamIdFocus,
    ],
  );
};
