import { keepPreviousData, UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { generateDownloadRequestsUrl } from 'api/routes';
import { UseApiFetchOptions } from 'api/types';
import { DownloadRequestsData } from 'shared/types/download-manager/downloadRequests';

export const DEFAULT_DOWNLOAD_REQUESTS_QUERY_KEY = 'fetchDownloadRequests';

type Args = {
  onSuccess?: (data: DownloadRequestsData) => void;
  onError?: (error?: unknown) => void;
  errorMessage?: string;
  queryRef?: string;
  queryParams?: {
    page?: number;
    size?: number;
    viewed?: boolean;
  };
  options?: UseApiFetchOptions;
};

interface FetchDownloadRequests {
  (args?: Args): UseQueryResult<DownloadRequestsData>;
}

const DEFAULT_QUERY_PARAMS = {
  page: 0,
  size: 15,
  sort: 'desc',
} as const;

export const useFetchDownloadRequests: FetchDownloadRequests = (args) => {
  const url = generateDownloadRequestsUrl({
    page: args?.queryParams?.page ?? DEFAULT_QUERY_PARAMS.page,
    size: args?.queryParams?.size ?? DEFAULT_QUERY_PARAMS.size,
    sort: `state.startedAt,${DEFAULT_QUERY_PARAMS.sort}`,
    viewed: args?.queryParams?.viewed,
  });

  const queryRef = [
    DEFAULT_DOWNLOAD_REQUESTS_QUERY_KEY,
    ...(args?.queryRef ? [args.queryRef] : []),
    ...(args?.queryParams?.page ? [`${args.queryParams.page}`] : []),
  ];

  return useFetchRequest<DownloadRequestsData>({
    queryRef,
    url,
    options: args?.options,
    placeholderData: keepPreviousData,
  });
};
