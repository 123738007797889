import { UseQueryResult } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';

import { queryClient } from 'api/config';
import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { AccountUrl, getOauth2LoginUrl } from 'api/routes';
import { UseApiFetchOptions } from 'api/types';
import { CONNECTION_QUERY_PARAM_NAME } from 'shared/constants/queryParams';
import { Client, User, UserAuthority, UserRole } from 'shared/types';

export interface AccountAPIResponse {
  id: string;
  clients: Client[];
  email: string;
  firstName: string;
  lastName: string;
  phoneAreaCode?: string | null;
  phoneNumber?: string | null;
  role: UserRole;
  avatarUrl?: string;
  locale: string;
  authorities: string[];
}

type FetchAccountParams = {
  onSuccess?: (user: User) => void;
  queryRef?: string[];
  options?: UseApiFetchOptions;
};

export const transformUser = (user: AccountAPIResponse): User => ({
  id: user.id,
  clients: user.clients,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  phoneAreaCode: user.phoneAreaCode ?? undefined,
  phoneNumber: user.phoneNumber ?? undefined,
  role: user.role as UserRole,
  avatarUrl: user.avatarUrl,
  locale: user.locale,
  authorities: user.authorities.map((authority) => authority as UserAuthority),
});

const DEFAULT_QUERY_REF = ['fetchAccount'];

export const useFetchAccount = ({
  queryRef = DEFAULT_QUERY_REF,
  onSuccess = () => {},
  options = {},
}: FetchAccountParams = {}): UseQueryResult<User> & {
  setQueryData: (user: User) => void;
} => {
  const fetchRequest = useFetchRequest<User>({
    queryRef: queryRef,
    url: AccountUrl,
    transformer: (response) => {
      const user = transformUser(response);
      onSuccess(user);
      return user;
    },
    options: {
      retry: false,
      refetchOnWindowFocus: true,
      refetchInterval: 900000,
      ...options,
    },
  });

  const handleOnError = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const connection = queryParams.get(CONNECTION_QUERY_PARAM_NAME);
    window.location.href = getOauth2LoginUrl(window.location.pathname, connection);
  }, []);

  useEffect(() => {
    if (fetchRequest.isError) handleOnError();
  }, [handleOnError, fetchRequest.data, fetchRequest.isSuccess, fetchRequest.error, fetchRequest.isError, onSuccess]);

  const setQueryData = useCallback((user: User) => queryClient.setQueryData(queryRef, user), [queryRef]);
  return { ...fetchRequest, setQueryData };
};
