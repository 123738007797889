import { Box, Button, Divider, List, ListItem, Popover, TextField, Tooltip } from '@mui/material';
import { Colors, fontSizes, themeBaseSpacing } from 'kognia-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import IconChevronDown from 'shared/components/icons/icon-chevron-down';
import IconDone from 'shared/components/icons/icon-done';
import { openSidebarWidth } from 'shared/components/sidebar-layout/SideBarLayout.styled';
import { useClientId } from 'shared/contexts/app-state/hooks/useClientId';
import { useUser } from 'shared/contexts/app-state/hooks/useUser';

const LIST_ITEM_HEIGHT = 40;
const VISIBLE_CLIENTS_AMOUNT = 7;
const PADDING = themeBaseSpacing;
const LIST_HEIGHT = `${VISIBLE_CLIENTS_AMOUNT * LIST_ITEM_HEIGHT + PADDING}px`;

type Props = {
  isSidebarOpen: boolean;
};

export const ClientDropdown = ({ isSidebarOpen }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const navigate = useNavigate();
  const user = useUser();
  const { clientId, setClientId } = useClientId();

  const open = Boolean(anchorEl);
  const hideText = !isSidebarOpen;
  const isLimitedListHeight = user.clients.length > VISIBLE_CLIENTS_AMOUNT;
  const getCurrentClient = () => user.clients.find((client) => client.id === clientId);
  const currentClientName = getCurrentClient()?.name || '';

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setSearchValue('');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleListItemClick = (id: string) => () => {
    setClientId(id);
    navigate(routes.HOME_PAGE);
    handleClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

  const clients = user.clients.filter((client) => {
    if (!searchValue.trim()) {
      return true;
    }

    return client.name.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase());
  });

  return (
    <>
      <Tooltip title={!isSidebarOpen && !open ? currentClientName : ''} placement={'right'}>
        <Button
          id='clients-menu-button'
          data-testid='clients-menu-button'
          aria-describedby={open ? 'clients-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          color={open ? 'primary' : 'inherit'}
          variant='outlined'
          disableElevation
          onClick={handleClick}
          endIcon={<IconChevronDown color={open ? 'primary' : 'inherit'} size='small' />}
          fullWidth
          sx={{
            justifyContent: hideText ? 'center' : 'space-between',
            paddingX: hideText ? 0 : 1,
            borderRadius: 1,
            // TODO use color from theme
            borderColor: Colors.iron,
            minWidth: 'auto',
            '& .MuiButton-endIcon': {
              marginLeft: 0,
              marginRight: 0,
            },
          }}
          size={'large'}
        >
          {!hideText && (
            <Box
              component='span'
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '200px',
              }}
            >
              {currentClientName}
            </Box>
          )}
        </Button>
      </Tooltip>

      <Popover
        id='clients-menu'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={(theme) => ({
          marginTop: theme.spacing(1),
          boxSizing: 'border-box',
          '& .MuiPaper-root': {
            minWidth: `calc(${openSidebarWidth}px - ${theme.spacing(4)})`,
            // TODO use color from theme
            border: `solid 1px ${Colors.ghost}`,
            boxShadow: '0 32px 40px -2px rgba(10, 22, 70, 0.12), 0 0 1px 0 rgba(10, 22, 70, 0.06)',
          },
        })}
      >
        <Box sx={{ overflowY: 'hidden' }}>
          {isLimitedListHeight && (
            <>
              <Box display='flex' justifyContent='center' alignItems='center'>
                <TextField
                  id='clients-menu-input'
                  size='small'
                  placeholder={t('switch-environment:search-input.placeholder')}
                  autoFocus
                  value={searchValue}
                  onChange={handleChange}
                  sx={(theme) => ({
                    width: `calc(100% - ${theme.spacing(3)})`,
                    margin: theme.spacing(1, 0),
                  })}
                />
              </Box>
              <Divider />
            </>
          )}

          <List
            sx={(theme) => ({
              maxHeight: isLimitedListHeight ? LIST_HEIGHT : 'auto',
              height: isLimitedListHeight ? LIST_HEIGHT : 'auto',
              padding: theme.spacing(0.5, 0),
              overflowY: 'auto',
            })}
          >
            {clients.length ? (
              clients.map(({ name, id }) => (
                <ListItem
                  key={id}
                  onClick={handleListItemClick(id)}
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: `${LIST_ITEM_HEIGHT}px`,
                    padding: theme.spacing(1, 2),
                    fontSize: fontSizes.default,
                    fontWeight: theme.typography.fontWeightMedium,
                    cursor: 'pointer',
                    '&: hover': {
                      backgroundColor: theme.palette.action.hover,
                    },
                  })}
                >
                  {name}
                  {id === clientId && <IconDone size='small' />}
                </ListItem>
              ))
            ) : (
              <ListItem>{t('common:clients-dropdown.no-environments-found')}</ListItem>
            )}
          </List>
        </Box>
      </Popover>
    </>
  );
};
