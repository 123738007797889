import queryString from 'query-string';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { logoutUrl, returnToQueryParam } from 'api/routes';
import { RETURN_TO_QUERY_PARAM_NAME } from 'shared/constants/queryParams';
import { useUnauthorize } from 'shared/contexts/app-state/hooks/useUnauthorize';

const logoutRedirect = (logoutUrl: string | undefined) => {
  if (logoutUrl) window.location.href = logoutUrl;
};

const fetchQueryRef = ['logoutAccount'];

export const useLogOutAccount = () => {
  const logout = useUnauthorize();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParamValues = queryString.parse(search);
  const externalReturnToQueryParam = queryParamValues[RETURN_TO_QUERY_PARAM_NAME];
  const queryParam =
    typeof externalReturnToQueryParam === 'string' && externalReturnToQueryParam
      ? externalReturnToQueryParam
      : returnToQueryParam;

  const url = `${logoutUrl}?${RETURN_TO_QUERY_PARAM_NAME}=${queryParam}`;

  const fetchResponse = useFetchRequest<{ logoutUrl: string }>({
    queryRef: fetchQueryRef,
    url,
    options: {
      enabled: true,
      retry: false,
    },
  });

  useEffect(() => {
    if (fetchResponse.isSuccess) {
      logout();
      if (!fetchResponse.data.logoutUrl) throw new Error('Logout URL is not defined');
      logoutRedirect(fetchResponse.data.logoutUrl);
    }
  }, [logout, fetchResponse.data, fetchResponse.isSuccess, fetchResponse.isError, navigate, t]);

  return fetchResponse;
};
