import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformMatchWithEpisodes } from 'api/match/transformers/match/matchWithEpisodes';
import { matchWithEpisodesUrl } from 'api/routes';
import { MatchWithEpisodes } from 'shared/types/match/types';

export const generateMatchWithPlaylistRef = (matchId: string, prefix: string) => {
  return [`fetch-match-with-episodes-playlistId:${matchId}`, prefix];
};

type UseMatchWithEpisodesReturn = UseQueryResult<MatchWithEpisodes>;

type Options = {
  recordingId: string;
  enabled?: boolean;
  prefix?: string;
};

export const useMatchWithEpisodes = ({
  recordingId,
  enabled = true,
  prefix = '',
}: Options): UseMatchWithEpisodesReturn => {
  const fetchQueryRef = generateMatchWithPlaylistRef(recordingId, prefix);

  return useFetchRequest<MatchWithEpisodes>({
    queryRef: fetchQueryRef,
    url: matchWithEpisodesUrl(recordingId),
    transformer: transformMatchWithEpisodes,
    options: { staleTime: 900000, enabled },
  });
};
