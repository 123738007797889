import { UseQueryResult } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { queryClient } from 'api/config';
import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformPlaylist } from 'api/playlist/transformers';
import { playlistUrl } from 'api/routes';
import { Playlist } from 'shared/types';

export const generateFetchPlaylistQueryRef = (playlistId: string) => `fetchPlaylist-playlistId:${playlistId}`;

type UseGetPlaylistResult = UseQueryResult<Playlist> & {
  setQueryData: (...args: any[]) => void;
};

interface Parameters {
  playlistId: string;
  onSuccess?: (data: Playlist) => void;
}

const fetchPlaylistQueryRef = 'fetchPlaylist';

export const invalidatePlaylistQuery = () => queryClient.invalidateQueries({ queryKey: [fetchPlaylistQueryRef] });

export const usePlaylist = ({ playlistId, onSuccess }: Parameters): UseGetPlaylistResult => {
  const queryRef = useMemo(() => [fetchPlaylistQueryRef, generateFetchPlaylistQueryRef(playlistId)], [playlistId]);
  const fetchRequest = useFetchRequest<Playlist>({
    queryRef: [fetchPlaylistQueryRef, generateFetchPlaylistQueryRef(playlistId)],
    url: playlistUrl(playlistId),

    transformer: (response) => {
      const playlist = transformPlaylist(response);
      onSuccess && onSuccess(playlist);
      return playlist;
    },
    options: {
      enabled: !!playlistId,
    },
  });

  const setQueryData = useCallback((data: Playlist) => queryClient.setQueryData(queryRef, data), [queryRef]);
  return { ...fetchRequest, setQueryData };
};
