import { ReactNode } from 'react';

import { ProtectedRouteInner } from 'shared/components/protected-route/ui/protected-route-inner/ProtectedRouteInner';
import { useIsUserAuthorized } from 'shared/contexts/app-state/hooks/useIsUserAuthorized';

type ProtectedRouteProps = {
  children: ReactNode;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const isUserAuthorized = useIsUserAuthorized();

  if (!isUserAuthorized) return null;

  return <ProtectedRouteInner>{children}</ProtectedRouteInner>;
};
