import Cookies from 'js-cookie';
import React, { useEffect } from 'react';

import { useSetUserAndClientIdForMetrics } from 'kognia/metrics/hooks/use-set-user-and-client-id-for-metrics';
import { APP_COLLECT_METRICS } from 'kognia/metrics/utils';
import { AppLoading } from 'shared/components/app-loading';
import { useAppData, UseAppDataType } from 'shared/contexts/app-state/hooks/useAppData';
import { useCurrentClientId } from 'shared/contexts/app-state/hooks/useCurrentClientId';
import { useIsUserAuthorized } from 'shared/contexts/app-state/hooks/useIsUserAuthorized';
import { useSetAppState } from 'shared/contexts/app-state/hooks/useSetAppState';
import { useSetCurrentClientId } from 'shared/contexts/app-state/hooks/useSetCurrentClientId';
import { useSetFeatureFlags } from 'shared/contexts/app-state/hooks/useSetFeatureFlags';
import { useSetIsAuthorized } from 'shared/contexts/app-state/hooks/useSetIsAuthorized';
import { useSetUser } from 'shared/contexts/app-state/hooks/useSetUser';
import usePageStateMachine, { PAGE_STATES } from 'shared/hooks/use-page-state-machine';
import { Client } from 'shared/types';
import { findClientOrThrow } from 'shared/utils/findClientOrThrow';
import { getBrandingFromClient } from 'shared/utils/getBrandingFromClient';
import { updateFavicon } from 'shared/utils/updateFavicon';

type AppStateProviderProps = { children: React.ReactNode };

const chooseInitialClientId = (userClients: Client[]) => {
  if (!userClients.length) {
    throw new Error('User has no environments');
  }

  const clientIdFromCookies = Cookies.get('current-client-id');
  if (clientIdFromCookies) {
    const client = userClients.find((client) => client.id === clientIdFromCookies);

    return client ? client.id : userClients[0].id;
  }

  return userClients[0].id;
};

export function AppStateProvider({ children }: AppStateProviderProps) {
  const setUser = useSetUser();
  const setUserAndClientIdForMetrics = useSetUserAndClientIdForMetrics();
  const setFeatureFlags = useSetFeatureFlags();
  const setIsAuthorized = useSetIsAuthorized();
  const isAuthorized = useIsUserAuthorized();
  const setCurrentClientId = useSetCurrentClientId();
  const setAppState = useSetAppState();
  const currentClientId = useCurrentClientId();

  const { current, data, PAGE_STATES } = usePageStateMachine<UseAppDataType>(() => useAppData());

  useEffect(() => {
    if (current.matches(PAGE_STATES.READY) && data?.user && data?.featureFlags) {
      const initialCurrentClientId = chooseInitialClientId(data.user.clients);
      APP_COLLECT_METRICS && setUserAndClientIdForMetrics(initialCurrentClientId, data.user);

      if (currentClientId) {
        updateFavicon(getBrandingFromClient(findClientOrThrow(data.user.clients, initialCurrentClientId)).faviconUrl);
      }

      setUser(data.user);
      setCurrentClientId(initialCurrentClientId);
      setIsAuthorized(true);
      setFeatureFlags(data.featureFlags);
      setAppState(PAGE_STATES.READY);
    } else {
      setAppState(current.value as PAGE_STATES);
    }
  }, [
    isAuthorized,
    setUserAndClientIdForMetrics,
    current.value,
    data?.user,
    data?.featureFlags,
    current,
    PAGE_STATES.READY,
    setUser,
    setIsAuthorized,
    setCurrentClientId,
    setFeatureFlags,
    setAppState,
    data,
    currentClientId,
  ]);

  const isAppSettled = current.matches(PAGE_STATES.READY) || current.matches(PAGE_STATES.ERROR);

  return (
    <>
      <AppLoading isLoaded={isAppSettled} />
      {children}
    </>
  );
}
